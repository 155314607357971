import { AttachMoney, Calculate, GridView, InsertChart, MonetizationOn, StackedLineChart } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider, MenuItem, MenuList, Stack, Tooltip, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../assets/logo'
import config from '../config'
import { useAuth } from '../modules/auth/data/auth-context'

export enum NavItemName {
  PortfolioGrid = 'portfolio-grid',
  Transactions = 'transactions',
  OrderBlotter = 'order-blotter',
  DataEngine = 'data-engine',
  Liquidity = 'liquidity',
  BankAccounts = 'bank-accounts',
  Compliance = 'compliance',
  Account = 'account',
  Settings = 'settings',
}

type NavBarProps = {
  onLogoutClick: () => void
  onNavigate: (navItemName: NavItemName) => void
}

function NavBar(props: NavBarProps) {
  const { onNavigate, onLogoutClick } = props
  const { t } = useTranslation('common')

  const { user } = useAuth()
  const isGridsOnlyUser = !!user?.is_grids_only_user

  return (
    <Stack
      sx={{
        width: '96px',
        justifyContent: 'space-between',
        bgcolor: 'gray.main',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'none',
      }}
    >
      <Stack alignItems="center" py="37px">
        <Logo width="90" />
      </Stack>
      <Stack flex={1} justifyContent="center">
        <MenuList>
          <NavItem onClick={() => onNavigate(NavItemName.PortfolioGrid)}>
            <GridView />
            <NavItemTitle>{t('navigation.portfolio_grid')}</NavItemTitle>
          </NavItem>
          <NavItem onClick={() => onNavigate(NavItemName.Transactions)}>
            <MonetizationOn />
            <NavItemTitle>{t('navigation.transactions')}</NavItemTitle>
          </NavItem>
          <NavItem
            disabled={isGridsOnlyUser || !config.enableUnreadyFeatures}
            onClick={() => onNavigate(NavItemName.OrderBlotter)}
          >
            <StackedLineChart />
            <NavItemTitle>{t('navigation.order_blotter')}</NavItemTitle>
          </NavItem>
          <NavItem disabled={isGridsOnlyUser} onClick={() => onNavigate(NavItemName.DataEngine)}>
            <InsertChart />
            <NavItemTitle>{t('navigation.data_engine')}</NavItemTitle>
          </NavItem>
          {config.enableUnreadyFeatures && (
            <NavItem
              disabled={isGridsOnlyUser || !config.enableUnreadyFeatures}
              onClick={() => onNavigate(NavItemName.Liquidity)}
            >
              <AttachMoney />
              <NavItemTitle>{t('navigation.liquidity')}</NavItemTitle>
            </NavItem>
          )}
          <NavItem disabled={isGridsOnlyUser} onClick={() => onNavigate(NavItemName.BankAccounts)}>
            <AttachMoney />
            <NavItemTitle>{t('navigation.bank_accounts')}</NavItemTitle>
          </NavItem>
          <NavItem disabled={isGridsOnlyUser} onClick={() => onNavigate(NavItemName.Compliance)}>
            <Calculate />
            <NavItemTitle>{t('navigation.compliance')}</NavItemTitle>
          </NavItem>

          <Divider sx={{ width: '50%', ml: '25%' }} />

          <NavItem onClick={() => onNavigate(NavItemName.Account)}>
            <AccountCircleIcon />
            <NavItemTitle>{t('menu.account')}</NavItemTitle>
          </NavItem>
          {!isGridsOnlyUser && (user?.is_superuser || user?.is_tenant_admin) && (
            <NavItem onClick={() => onNavigate(NavItemName.Settings)}>
              <SettingsIcon />
              <NavItemTitle>{t('menu.settings')}</NavItemTitle>
            </NavItem>
          )}
        </MenuList>
      </Stack>
      <Stack>
        <Tooltip title={t('menu.logout')} arrow disableInteractive>
          <div>
            <NavItem onClick={onLogoutClick}>
              <LogoutIcon sx={{ my: 1.5 }} />
            </NavItem>
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  )
}

export default NavBar

export type NavItemProps = {
  disabled?: boolean
  onClick: () => void
  children: ReactNode
}

function NavItem({ disabled, onClick, children }: NavItemProps) {
  return (
    <MenuItem
      disabled={disabled}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 2.25,
        gap: 0.75,
        color: 'rgba(255, 255, 255, 0.56)',
        '&:hover': {
          backgroundColor: 'rgba(120, 220, 232, 0.08)',
        },
        '.MuiTouchRipple-child': {
          backgroundColor: 'rgba(120, 220, 232, 0.2)',
        },
      }}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  )
}

function NavItemTitle({ children }: { children: ReactNode }) {
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '12px',
        color: 'inherit',
      }}
    >
      {children}
    </Typography>
  )
}
