import { useQuery } from '@tanstack/react-query'
import api from '../../../services/data/api'

function useBankAccountsGridQuery(bankAccountId: string | undefined) {
  return useQuery({
    queryKey: ['bank-accounts', bankAccountId, 'grid'],
    queryFn: () => {
      if (!bankAccountId) {
        return null
      }
      return api.getBankAccountsGrid(bankAccountId)
    },
  })
}

export default useBankAccountsGridQuery
