import { Box, Stack } from '@mui/system'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CollapsibleSideNavigation from '../../../components/collapsible-side-navigation'
import AppLayout from '../../../components/layouts/app-layout'
import ListItem from '../../../components/list-item'
import ListItemGroup from '../../../components/list-item-group'
import useOpenState from '../../../utils/hooks/use-open-state'
import usePortfoliosQuery from '../../portfolios/data/use-portfolios-query'
import useBankAccountsGridQuery from '../data/use-bank-accounts-grid-query'
import useBankAccountsNavigatorQuery from '../data/use-bank-accounts-navigator-query'
import useCancelTransactionMutation from '../data/use-cancel-transaction-mutation'
import BankAccountsGrid, { AgCellData } from './bank-accounts-grid'
import BankAccountsHeader from './bank-accounts-header'
import ContextMenu from './context-menu'
import EnterBankTransactionModal from './enter-bank-transaction-modal'
import EnterCounterpatySettlementModal from './enter-counterpary-settlement-modal'

type BankAccountsRootProps = {
  onBankAccountSelect: (bankAccountId: number, holderLei: string) => void
}

type ContextMenuState = {
  coordinates: { mouseX: number; mouseY: number }
  txnRef: string
  isBankTxn: boolean
}

function BankAccountsRoot(props: BankAccountsRootProps) {
  const { onBankAccountSelect } = props

  const { t } = useTranslation('bankAccounts')

  const [gridRef, setGridRef] = useState<RefObject<AgGridReact> | null>(null)

  const { bankAccountId, holderLei } = useParams()

  const nav = useOpenState({ open: true })
  const counterpartySettlementModal = useOpenState()
  const bankTransactionModal = useOpenState()

  const [contextMenu, setContextMenu] = useState<ContextMenuState | null>(null)
  const [editBankTxnRef, setEditBankTxnRef] = useState<string | null>(null)
  const [editSettlementRef, setEditSettlementTxnRef] = useState<string | null>(null)

  const cancelTransaction = useCancelTransactionMutation()

  const navigatorQuery = useBankAccountsNavigatorQuery()
  const navigator = navigatorQuery.data?.data || null
  const accounts = navigator?.accounts || []

  const portfoliosQuery = usePortfoliosQuery()
  let portfolios = portfoliosQuery.data?.data || []
  portfolios = portfolios.filter((p) => p.lei === holderLei)

  const gridQuery = useBankAccountsGridQuery(bankAccountId)
  const grid = gridQuery.data?.data || null

  const isLoading = gridQuery.isFetching || cancelTransaction.isLoading

  useEffect(() => {
    if (!bankAccountId && navigatorQuery.isSuccess) {
      const account = accounts[0]
      if (account) {
        onBankAccountSelect(account.account_id, account.holder_lei)
      }
    }
  }, [bankAccountId, navigatorQuery])

  function reloadData() {
    navigatorQuery.refetch()
    gridQuery.refetch()
  }

  function handleContextMenuOpen(event: MouseEvent, cell: AgCellData) {
    event.preventDefault()

    setContextMenu({
      coordinates: {
        mouseX: event.clientX,
        mouseY: event.clientY,
      },
      txnRef: cell.txnRef,
      isBankTxn: cell.isBankTxn,
    })
  }

  function handleCancelTransaction() {
    if (bankAccountId && contextMenu) {
      const txnRef = contextMenu.txnRef
      setContextMenu(null)

      cancelTransaction.mutate(
        { bankAccountId, txnRef },
        {
          onSuccess: () => gridQuery.refetch(),
        }
      )
    }
  }

  function handleEditTransaction() {
    if (contextMenu) {
      if (contextMenu.isBankTxn) {
        setEditBankTxnRef(contextMenu.txnRef)
        bankTransactionModal.open()
      } else {
        setEditSettlementTxnRef(contextMenu.txnRef)
        counterpartySettlementModal.open()
      }
      setContextMenu(null)
    }
  }

  if (!bankAccountId) {
    if (navigatorQuery.isSuccess && accounts.length === 0) {
      return (
        <AppLayout direction="row">
          <Box sx={{ p: 2 }}>{t('no_bank_accounts')}</Box>
        </AppLayout>
      )
    }
    return null
  }

  return (
    <>
      <AppLayout direction="row">
        <CollapsibleSideNavigation title={t('navigation.title')} open={nav.isOpen} onClose={nav.close}>
          <ListItemGroup isDefaultOpen title={t('navigation.accounts')}>
            {accounts.map((account) => (
              <ListItem
                key={account.account_id}
                title={account.account_name}
                isSelected={String(account.account_id) === bankAccountId}
                onClick={() => onBankAccountSelect(account.account_id, account.holder_lei)}
              />
            ))}
          </ListItemGroup>
        </CollapsibleSideNavigation>

        <Stack flex={1} overflow="hidden">
          <BankAccountsHeader
            title={grid?.account_name || ''}
            currency={grid?.currency || ''}
            showLoading={isLoading}
            isNavOpen={nav.isOpen}
            onOpenNavClick={nav.open}
            onNewCounterpartyTransfer={counterpartySettlementModal.open}
            onNewBankTransfer={bankTransactionModal.open}
            onReloadData={reloadData}
          />

          <Stack sx={{ height: '100%', px: 2, pb: 2, overflow: 'hidden' }}>
            <BankAccountsGrid grid={grid} onContextMenuOpen={handleContextMenuOpen} onForwardGridRef={setGridRef} />
          </Stack>
        </Stack>
      </AppLayout>

      <EnterCounterpatySettlementModal
        open={counterpartySettlementModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editSettlementRef}
        onSaved={gridQuery.refetch}
        onClose={() => {
          setEditSettlementTxnRef(null)
          counterpartySettlementModal.close()
        }}
      />
      <EnterBankTransactionModal
        open={bankTransactionModal.isOpen}
        bankAccountId={bankAccountId}
        txnRef={editBankTxnRef}
        portfolios={portfolios}
        onSaved={gridQuery.refetch}
        onClose={() => {
          setEditBankTxnRef(null)
          bankTransactionModal.close()
        }}
      />
      <ContextMenu
        coordinates={contextMenu?.coordinates}
        onEdit={handleEditTransaction}
        onCancel={handleCancelTransaction}
        onClose={() => setContextMenu(null)}
        onCopy={() => gridRef?.current?.api.copySelectedRangeToClipboard()}
      />
    </>
  )
}

export default BankAccountsRoot
