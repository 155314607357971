import { AccountCircle } from '@mui/icons-material'
import { Chip, CircularProgress, Stack, Typography } from '@mui/material'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PageHeaderContainer from '../../../components/page-header-container'
import useUserInfo from '../../auth/data/use-user-info'
import TenantMenu from './tenant-menu'

type AccountHeaderProps = {
  isLoading?: boolean
  onSwitchTenantSuccess: () => void
}

function AccountHeader(props: AccountHeaderProps) {
  const { isLoading, onSwitchTenantSuccess } = props

  const { t } = useTranslation('account')

  const { user } = useUserInfo()
  const isGridsOnlyUser = !!user?.is_grids_only_user

  const [tenantMenuAnchorEl, setTenantMenuAnchorEl] = useState<HTMLElement | null>(null)

  function handleTenantMenuOpen(event: MouseEvent<HTMLElement>) {
    setTenantMenuAnchorEl(event.currentTarget)
  }

  function handleTenantMenuClose() {
    setTenantMenuAnchorEl(null)
  }

  return (
    <PageHeaderContainer>
      <Stack
        direction="row"
        sx={{
          gap: 1,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Typography variant="h6">{t('my_account')}</Typography>
        {isLoading && <CircularProgress size={24} sx={{ m: '8px' }} />}
      </Stack>
      {!isGridsOnlyUser && (
        <Chip icon={<AccountCircle />} label={`${t('tenant')}: ${user?.tenant_name}`} onClick={handleTenantMenuOpen} />
      )}
      <TenantMenu
        anchorEl={tenantMenuAnchorEl}
        onClose={handleTenantMenuClose}
        onSwitchTenantSuccess={onSwitchTenantSuccess}
      />
    </PageHeaderContainer>
  )
}

export default AccountHeader
