import { AddBox, ChevronRight } from '@mui/icons-material'
import { Chip, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { MouseEvent, MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReloadButton } from '../../../components/buttons/reload-button'
import PageHeaderContainer from '../../../components/page-header-container'

type BankAccountsHeaderProps = {
  title: string
  currency: string
  showLoading: boolean
  isNavOpen: boolean
  onOpenNavClick: MouseEventHandler
  onNewCounterpartyTransfer: () => void
  onNewBankTransfer: () => void
  onReloadData: () => void
}

function BankAccountsHeader(props: BankAccountsHeaderProps) {
  const {
    title,
    currency,
    showLoading,
    isNavOpen,
    onOpenNavClick,
    onNewCounterpartyTransfer,
    onNewBankTransfer,
    onReloadData,
  } = props

  return (
    <PageHeaderContainer>
      <Stack flex="1" direction="row" alignItems="center" gap={1}>
        {!isNavOpen && (
          <IconButton onClick={onOpenNavClick}>
            <ChevronRight color="primary" />
          </IconButton>
        )}
        {!!title && (
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        )}
        {!!currency && <Chip label={<Typography color="gray.700">{currency}</Typography>} />}
        <ReloadButton loading={showLoading} onClick={onReloadData} />
      </Stack>

      <Stack direction="row" alignItems="center">
        <NewTransferMenuButton
          onNewCounterpartyTransfer={onNewCounterpartyTransfer}
          onNewBankTransfer={onNewBankTransfer}
        />
      </Stack>
    </PageHeaderContainer>
  )
}

export default BankAccountsHeader

type NewTransferMenuButtonProps = {
  onNewCounterpartyTransfer: () => void
  onNewBankTransfer: () => void
}

function NewTransferMenuButton(props: NewTransferMenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = !!anchorEl

  const { t } = useTranslation('bankAccounts')

  function openMenu(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function closeMenu() {
    setAnchorEl(null)
  }

  function handleNewTrade() {
    props.onNewCounterpartyTransfer()
    closeMenu()
  }

  function handleNewCashflow() {
    props.onNewBankTransfer()
    closeMenu()
  }

  return (
    <div>
      <IconButton
        id="new-transfer-button"
        aria-controls={open ? 'new-transfer-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={openMenu}
        color="primary"
        sx={{ color: 'gray.300', padding: '4px', margin: '4px' }}
      >
        <AddBox color="primary" />
      </IconButton>
      <Menu
        id="new-transfer-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'new-transfer-button',
          sx: { minWidth: '160px' },
        }}
      >
        <MenuItem onClick={handleNewTrade}>{t('header.new_counterparty_transfer')}</MenuItem>
        <MenuItem onClick={handleNewCashflow}>{t('header.new_bank_transfer')}</MenuItem>
      </Menu>
    </div>
  )
}
