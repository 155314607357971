import { Cancel, ContentCopy, Edit } from '@mui/icons-material'
import {
  ClickAwayListener,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverPosition,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

type ContextMenuProps = {
  coordinates: { mouseX: number; mouseY: number } | undefined
  onClose: () => void
  onEdit: () => void
  onCancel: () => void
  onCopy: () => void
}

function ContextMenu(props: ContextMenuProps) {
  const { coordinates, onClose, onCancel, onEdit, onCopy } = props

  const { t } = useTranslation('bankAccounts')

  const isOpen = !!coordinates
  const anchorPosition: PopoverPosition | undefined = !!coordinates
    ? { top: coordinates.mouseY, left: coordinates.mouseX }
    : undefined

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Menu
        open={isOpen}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        disableScrollLock
        transitionDuration={{ exit: 0 }}
        sx={{ position: 'static' }}
        PaperProps={{
          sx: { zIndex: 5 },
        }}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <Edit sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('context_menu.edit')}</Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={onCancel}>
          <ListItemIcon>
            <Cancel sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('context_menu.cancel')}</Typography>
          </ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem onClick={onCopy}>
          <ListItemIcon>
            <ContentCopy sx={{ color: 'gray.300' }} />
          </ListItemIcon>
          <ListItemText>
            <Typography fontSize="14px">{t('common:copy')}</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </ClickAwayListener>
  )
}

export default ContextMenu
