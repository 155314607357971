import { NaiveDate } from '../../../utils/dates'
import { AssetCategory } from './asset-static-data'
import { DatapointType, DatapointValue } from './datapoint'
import { DealTransaction } from './deal'

export type TradeTicketResponse = {
  identifier_types: {
    identifier_type: string
    identifier_name: string
  }[]
  currencies: {
    [key: string]: string
  }
  indexes: {
    [key: string]: string
  }
  ref_rates: {
    [key: string]: string
  }
  regular_curves: {
    [key: string]: string
  }
  brokers: {
    broker_id: number
    broker_lei: string
    broker_name: string
  }[]
  strategy_tags: string[]
  asset_types: TradeTicketResponseAssetType[]
  trade_profiles: {
    tag: TradeProfile
    name: string
    analytics: string[]
  }[]
  cashflow_types: {
    tag: string
    name: string
  }[]
}

export type TradeTicketResponseAssetType = {
  asset_type_tag: string
  asset_type_name: string
  static_fields: {
    datapoint_ref: string
    datapoint_name: string
    datapoint_type: DatapointType
    classification_id: number | null
    active_alias: string
    is_mandatory: boolean
    asset_category: AssetCategory
  }[]
  trade_profiles: TradeProfile[]
  allow_custom: boolean
  custom_assets?: {
    asset_ref: string
    description: string
  }[]
}

const tradeProfiles = [
  'standard',
  'standard_bond',
  'open_bond_by_spread',
  'close_bond_by_spread',
  'standard_repo',
  'trs',
  'future',
  'fx_spot',
  'fx_forward',
  'ndf',
  'cashflow',
  'close_repo',
  'close_fx_forward',
  'close_ndf',
  'close_trs',
] as const

export type TradeProfile = (typeof tradeProfiles)[number]
// type check for when profiles are added on the backend
// this way we have to validate things on the frontend
// | (string & {})

export function isTradeProfileSupported(tradeProfile: TradeProfile) {
  return tradeProfiles.includes(tradeProfile as any)
}

export type AssetMeta = {
  currency_asset_ref: string
  currency_symbol: string
  contract_size: number | null
}

export type TradeSecurity =
  | {
      by_asset_ref: string
    }
  | {
      insert_otc: string
    }
  | {
      create_new_custom: {
        asset_type: string
        short_name: string
      }
    }

export type TradeDirection = 'buy' | 'sell' | 'cover' | 'short'

export type Leg = {
  id: number

  // used for transaction correction
  transactionRef: string | undefined

  // used for trade confirmation
  dealTransactions: DealTransaction[] | undefined

  security: {
    identifier: string
    // todo: use proper type
    identifierType: string
    assetRef: string
    assetType: string
    assetTypeName: string
    assetName: string
  }

  staticFields: LegField[]

  tradeAssetType: TradeTicketResponseAssetType | undefined
  profiles: TradeTicketResponse['trade_profiles']

  aliasError: string

  tradeProfile: TradeProfile
  tradeDirection: TradeDirection | null
} & TradeParticularsFields

export type TradeParticularsFields = {
  value: string
  price: string
  numberOf: string
  quantity: string
  notional: string
  spread: string
  accruedInterest: string
  sinkFactor: string
  haircut: string
  inceptionFx: string
  fundingAmountLc: string
  repoFundingAmount: string
  trsFundingAmount: string
  quoteCurrency: string
  spotRate: string
  forwardRate: string
  quoteAmount: string
  settlementDate: Date | null
  settlementAmount: string
  settlementPrice: string
  actualSettlementDate: Date | null
  fixingDate: Date | null
  fixingPrice: string
  fixingRate: string
  isGiveUp: boolean
  ndfRate: string
  initialMarginRate: string
  settleOnQuote: boolean
  marginInQuoteCurrency: boolean
  isFullyFunded: boolean
  cashflowType: string
  relatedAsset: string
}

export type LegField = {
  datapointRef: string
  value: DatapointValue
  security?: Leg['security']
}

export type EditLegAsset = {
  legId: number
  assetRef: string
  assetDescription: string
  datapointRef: string
  datapointType: DatapointType
  datapointName: string
  datapointValue: DatapointValue | undefined
  classificationId: number | null
  assetCategory: AssetCategory | undefined
}

export type ConsignNewTicketPayload = {
  deals: ConsignNewDeal[]
  allocations: ConsignmentAllocations
}

export type ConsignNewDeal = {
  broker_account_id: number
  executed_at: string
  note: string | null
  legs: ConsignmentDealLeg[]
}

export type CorrectTransactionPayload = {
  txn_datetime: NaiveDate
  broker_account_id: number
  note: string | null
  legs: ConsignmentDealLeg[]
  allocations: ConsignmentAllocations
}

export type ConfirmDealsPayload = {
  deals: ConfirmDeal[]
  allocations: ConsignmentAllocations
}

export type ConfirmDeal = {
  deal_ref: string
  confirmed_at: string
  broker_account_id: number
  note: string | null
  legs: ConsignmentDealLeg[]
}

export type ConsignmentDealLeg = {
  security: TradeSecurity
  static_fields: ConsignmentStaticField[]
  trade_details: TradeDetails
  strategy: string | null
}

type ConsignmentStaticField = {
  datapoint_ref: string
  value: DatapointValue
}

type ConsignmentAllocations = {
  [key: string]: number
}

export type CloseOutSegmentPayload = {
  txn_datetime: string
  note: string | null
  trade_details: TradeDetails
}

export type TradeDetails =
  | {
      standard_bond: {
        notional: number
        clean_price: number
        accrued_interest: number
        sink_factor: number | undefined
        settlement_date: string
      }
    }
  | {
      open_bond_by_spread: {
        consideration: number
        spread_bps: number
        settlement_date: string
      }
    }
  | {
      close_bond_by_spread: {
        notional: number
        spread_bps: number
        settlement_date: string
      }
    }
  | {
      future: {
        quantity: number
        price: number
      }
    }
  | {
      standard_repo: {
        notional: number
        clean_price: number
        accrued_interest: number
        haircut: number
        sink_factor: number | undefined
        inception_fx: number | undefined
        settlement_date: string
      }
    }
  | {
      fx_spot: {
        notional: number
        spot_rate: number
        quote_currency: string
        settlement_date: string
        is_give_up: boolean
      }
    }
  | {
      fx_forward: {
        notional: number
        forward_rate: number
        settlement_date: string
        initial_margin_rate: number | undefined
        is_give_up: boolean
      }
    }
  | {
      ndf: {
        notional: number
        ndf_rate: number
        settle_on_quote: boolean
        fixing_date: string
        settlement_date: string
        initial_margin_rate: number | undefined
        margin_in_quote_currency: boolean
        is_give_up: boolean
      }
    }
  | {
      trs: {
        notional: number
        clean_price: number
        accrued_interest: number
        haircut: number
        sink_factor: number | undefined
        settlement_date: string
        is_fully_funded: boolean
        inception_fx: number | undefined
      }
    }
  | {
      cashflow: {
        amount: number
        cashflow_type: string
        related_asset: string | undefined
        settlement_date: string
      }
    }
  | {
      close_repo: {
        settlement_date: string
        settlement_amount: number
      }
    }
  | {
      close_fx_forward: null
    }
  | {
      close_ndf: {
        fixing_rate: number
      }
    }
  | {
      close_trs: {
        settlement_date: string
        settlement_price: number
        settlement_amount: number
      }
    }
