import { Typography } from '@mui/material'
import { ColDef, GetRowIdParams, ICellRendererParams, IHeaderParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useEffect, useMemo, useRef } from 'react'
import DatapointCell from '../../../components/data-table/datapoint-cell'
import { formatValueToCopy } from '../../../services/data/datapoint-formatting'
import { BankAccountsGridResponse } from '../../../services/data/types/bank-accounts'
import { DatapointValue } from '../../../services/data/types/datapoint'

type BankAccountsGridProps = {
  grid: BankAccountsGridResponse | null
  onContextMenuOpen: (event: MouseEvent, data: AgCellData) => void
  onForwardGridRef: (ref: RefObject<AgGridReact>) => void
}

export type AgCellData = {
  rowKey: string
  txnRef: string
  isBankTxn: boolean
  cells: {
    [headingKey: string]: DatapointValue
  }
}

type AgCellValue = AgCellData['cells'][string]

function BankAccountsGrid(props: BankAccountsGridProps) {
  const { grid, onContextMenuOpen, onForwardGridRef } = props

  const gridRef = useRef<AgGridReact>(null)

  useEffect(() => onForwardGridRef(gridRef), [grid])

  const [cols, rows] = useMemo(() => {
    const cols =
      grid?.headings.map((heading, index) => {
        const col: ColDef = {
          colId: heading.key,
          field: `cells.${heading.key}`,
          headerName: heading.title,
          lockPinned: index > 0,
          pinned: index === 0,
        }
        return col
      }) || []

    const rows =
      grid?.rows.map((row) => {
        return row.cells.reduce<AgCellData>(
          (data, cell) => {
            data.cells[cell.heading_key] = cell.value
            return data
          },
          {
            rowKey: row.row_key,
            txnRef: row.txn_ref,
            isBankTxn: row.is_bank_txn,
            cells: {},
          }
        )
      }) || []

    return [cols, rows] as const
  }, [grid])

  if (!grid) {
    return null
  }

  return (
    <>
      <div style={{ height: '100%', marginTop: '16px' }}>
        <AgGridReact
          ref={gridRef}
          className="ag-theme-alpine-dark"
          columnDefs={cols}
          rowData={rows}
          rowHeight={32}
          rowSelection="single"
          animateRows
          enableRangeSelection
          suppressColumnVirtualisation
          suppressRowHoverHighlight
          processCellForClipboard={(params) => {
            return formatValueToCopy(params.value)
          }}
          getContextMenuItems={() => {
            // disable context menu by giving it no items,
            // as setting suppressContextMenu prevents row selection on right click
            return []
          }}
          onCellContextMenu={(event) => {
            // select row on right click
            event.node.setSelected(true)
          }}
          getRowId={(params: GetRowIdParams<AgCellData>) => {
            return params.data.rowKey
          }}
          components={{
            agColumnHeader: AgColumnHeader,
          }}
          defaultColDef={{
            resizable: true,
            suppressMovable: true,
            suppressMenu: true,
            valueParser: () => {
              // used when editing cell, but we don't need it as we have our own component
              // but there was a warning on the console when not provided
              // https://www.ag-grid.com/react-data-grid/value-parsers/#value-parser
            },
            cellRenderer: (props: ICellRendererParams<AgCellData, AgCellValue>) => {
              const data = props.data
              const value = props.value

              return (
                <div
                  style={{ height: '100%' }}
                  onContextMenu={(event) => {
                    if (data) {
                      onContextMenuOpen(event, data)
                    }
                  }}
                >
                  {!!value && <DatapointCell datapoint={value} />}
                </div>
              )
            },
          }}
        />
      </div>
    </>
  )
}

export default BankAccountsGrid

function AgColumnHeader(params: IHeaderParams) {
  const colDef = params.column.getUserProvidedColDef()

  return (
    <Typography fontSize="14px" fontWeight="500">
      {colDef?.headerName || ''}
    </Typography>
  )
}
