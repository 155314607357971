import { DeviceHub, LibraryAdd, MonetizationOn } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import AppLayout from '../../../components/layouts/app-layout'
import { parseGridData } from '../../../services/data/grid-data-parsing'
import { OrderPlacementsRow } from '../../../services/data/types/order'
import useIsFirstRender from '../../../utils/hooks/use-is-first-render'
import useOpenState from '../../../utils/hooks/use-open-state'
import useUserInfo from '../../auth/data/use-user-info'
import PortfolioTable from '../../portfolios/components/portfolio-table'
import { useFilterOptions } from '../../portfolios/data/use-filter-options'
import useViewConfigState from '../../portfolios/data/use-view-config-state'
import { useBrokersQuery } from '../data/use-broker-queries'
import { useOrderBatchGridQuery } from '../data/use-order-batch-grid-query'
import { useOrderBatchesQuery } from '../data/use-order-batches-query'
import { useOrderQuery } from '../data/use-order-query'
import { OrderBlotterHeader } from './order-blotter-header'
import { OrderBlotterNav } from './order-blotter-nav'
import { OrderManualFillModal } from './order-manual-fill-modal'
import OrderPanel from './order-panel'
import { OrderPlacementModal } from './order-placement-modal'

type OrderBlotterRootProps = {
  onOrderBatchChange: (orderBatchRef: string) => void
}

function OrderBlotterRoot(props: OrderBlotterRootProps) {
  const { onOrderBatchChange } = props

  const { t } = useTranslation('orderBlotter')

  const { user } = useUserInfo()
  const isGridsOnlyUser = !!user?.is_grids_only_user

  const isFirstRender = useIsFirstRender()

  const nav = useOpenState({ open: true })
  const allocationsPanel = useOpenState({ open: true })
  const placementsPanel = useOpenState({ open: true })
  const fillsPanel = useOpenState({ open: true })

  const placementModal = useOpenState()
  const manualFillModal = useOpenState()
  const [editPlacementBrokerId, setEditPlacementBrokerId] = useState<number>()

  const { orderBatchRef } = useParams()
  const [orderRef, setOrderRef] = useState('')

  const {
    viewConfigState,
    setAggregations,
    setSortBy,
    moveColumn,
    toggleHiddenColumn,
    setHiddenColumns,
    addFilters,
    deleteFilter,
    setFilters,
    resetState,
  } = useViewConfigState()

  const { aggregations, filters, sortBy, columns } = viewConfigState

  const orderBatchesResponse = useOrderBatchesQuery()
  const orderBatches = orderBatchesResponse.data?.data || null

  useEffect(() => {
    if (!isFirstRender && !orderBatchRef && orderBatches?.length && orderBatchesResponse.isSuccess) {
      handleOrderBatchChange(orderBatches[0]!.order_batch_ref)
    }
  }, [isFirstRender, orderBatchRef, orderBatches, orderBatchesResponse])

  const gridResponse = useOrderBatchGridQuery(orderBatchRef, aggregations, filters)
  const grid = gridResponse.data?.data || null
  const parsedGrid = parseGridData(grid, columns, sortBy)
  const filterOptions = useFilterOptions(parsedGrid?.columnsOptions)
  const columnsOptions = parsedGrid?.columnsOptions || []
  const shownColumnsCount = parsedGrid?.shownColumnsCount

  const orderBatch = orderBatches?.find((ob) => ob.order_batch_ref === orderBatchRef)
  const title = orderBatch?.order_batch_name || ''

  const orderResponse = useOrderQuery(orderRef)
  const order = orderResponse.data?.data || null
  const hasOrder = !!orderRef && !!order
  const minimizePanels = !allocationsPanel.isOpen && !placementsPanel.isOpen && !fillsPanel.isOpen

  const brokersResponse = useBrokersQuery(order?.lei)
  const brokers = brokersResponse.data?.data || []

  const showLoading = orderBatchesResponse.isFetching || gridResponse.isFetching || orderResponse.isFetching

  function handleOrderBatchChange(orderBatchRef: string) {
    resetState()
    onOrderBatchChange(orderBatchRef)
  }

  function handleToggleHiddenColumn(datapointRef: string) {
    if (parsedGrid) {
      toggleHiddenColumn(datapointRef, parsedGrid.originalHeadings)
    }
  }

  function handleUnhideAllColumns() {
    setHiddenColumns([])
  }

  function handleMoveColumn(fromDatapointRef: string, toDatapointRef: string) {
    if (parsedGrid) {
      moveColumn(fromDatapointRef, toDatapointRef, parsedGrid.originalHeadings)
    }
  }

  function handleSelectOrder(selectedOrderRef: string) {
    setOrderRef((prevOrderRef) => {
      return prevOrderRef === selectedOrderRef ? '' : selectedOrderRef
    })
  }

  function handlePlaceOrder(orderRef: string) {
    setOrderRef(orderRef)
    placementModal.open()
  }

  function handleEditPlacementClick(row: OrderPlacementsRow) {
    setEditPlacementBrokerId(row.broker_account_id)
    placementModal.open()
  }

  function handleClosePlacementModal() {
    setEditPlacementBrokerId(undefined)
    placementModal.close()
  }

  function reloadData() {
    orderBatchesResponse.refetch()
    gridResponse.refetch()
    orderResponse.refetch()
  }

  return (
    <>
      <AppLayout direction="row">
        <OrderBlotterNav
          orderBatches={orderBatches}
          selectedOrderBatchRef={orderBatchRef}
          isNavOpen={nav.isOpen}
          onNavClose={nav.close}
          onOrderBatchSelect={handleOrderBatchChange}
        />

        <Stack flex="1" sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
          <OrderBlotterHeader
            isNavOpen={nav.isOpen}
            showLoading={showLoading}
            title={title}
            onNavOpen={nav.open}
            onReloadData={reloadData}
          />

          <Stack flex="1" sx={{ height: 'calc(100% - 56px)', px: 2 }}>
            <PortfolioTable
              data={parsedGrid}
              aggregations={aggregations}
              filters={filters}
              filterOptions={filterOptions}
              columnsOptions={columnsOptions}
              shownColumnsCount={shownColumnsCount}
              sortBy={sortBy}
              hideModeller
              hideLockCellButton
              isGridsOnlyUser={isGridsOnlyUser}
              onSortChange={setSortBy}
              onAggregationsChange={setAggregations}
              onToggleHiddenColumn={handleToggleHiddenColumn}
              onUnhideAllColumns={handleUnhideAllColumns}
              onMoveColumn={handleMoveColumn}
              onAddFilters={addFilters}
              onDeleteFilter={deleteFilter}
              onDeleteAllFilters={() => setFilters(null)}
              onSelectOrder={handleSelectOrder}
              onPlaceOrder={handlePlaceOrder}
            />

            {hasOrder && (
              <Stack
                direction="row"
                sx={{
                  flex: '0 0 auto',
                  maxHeight: '60%',
                  my: 2,
                  gap: 1,
                  overflow: 'auto',
                  '& > *': {
                    mb: 1,
                  },
                }}
              >
                <OrderPanel
                  isMinimized={minimizePanels}
                  isOpen={allocationsPanel.isOpen}
                  title={t('allocations')}
                  table={order.allocations}
                  Icon={DeviceHub}
                  onToggle={allocationsPanel.toggle}
                />
                <OrderPanel
                  isMinimized={minimizePanels}
                  isOpen={placementsPanel.isOpen}
                  title={t('placements')}
                  table={order.placements}
                  actionButtonText={t('new_placement')}
                  editButtonText={t('edit_placement')}
                  onActionButtonClick={placementModal.open}
                  Icon={LibraryAdd}
                  onToggle={placementsPanel.toggle}
                  onEditRowClick={handleEditPlacementClick}
                />
                <OrderPanel
                  isMinimized={minimizePanels}
                  isOpen={fillsPanel.isOpen}
                  title={t('fills')}
                  table={order.fills}
                  actionButtonText={t('manual_fill')}
                  onActionButtonClick={manualFillModal.open}
                  Icon={MonetizationOn}
                  onToggle={fillsPanel.toggle}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </AppLayout>

      <OrderPlacementModal
        open={placementModal.isOpen}
        order={order}
        brokers={brokers}
        editBrokerId={editPlacementBrokerId}
        onCreated={orderResponse.refetch}
        onClose={handleClosePlacementModal}
      />
      <OrderManualFillModal
        open={manualFillModal.isOpen}
        order={order}
        brokers={brokers}
        onCreated={orderResponse.refetch}
        onClose={manualFillModal.close}
      />
    </>
  )
}

export default OrderBlotterRoot
