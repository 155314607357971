import { BuildCircle, CalendarToday, FilterAlt, TableChart, ViewColumn } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { MouseEvent, RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ColumnsMenu from '../../../components/data-table/columns-menu'
import DataTable from '../../../components/data-table/data-table'
import FilterButton from '../../../components/filters/filter-button'
import FilterMenu from '../../../components/filters/filter-menu'
import { GridDataFilterOption, parseFilters } from '../../../services/data/filter-parsing'
import {
  PanelType,
  ParsedGridColumnOption,
  ParsedGridData,
  ParsedGridDataCell,
  ParsedGridDataRow,
  getAggregatePathGroupLevel,
} from '../../../services/data/grid-data-parsing'
import { DatapointType, DatapointValueType } from '../../../services/data/types/datapoint'
import { GridCellOption } from '../../../services/data/types/grid-data'
import { GridDataViewFilters, GridDataViewSortBy } from '../../../services/data/types/grid-data-view'
import { TxnStatus } from '../../../services/data/types/transaction'
import { DeleteFilterPayload } from '../data/use-view-config-state'
import AsOfDateMenu from './as-of-date-menu'
import ContextMenu from './context-menu'
import GroupBySection from './group-by-section'
import ModellerMenu from './modeller-menu'
import { SelectedTransaction } from './transactions-grid'

type PortfolioTableProps = {
  data: ParsedGridData | null
  aggregations: string[]
  filters: GridDataViewFilters | null
  filterOptions: GridDataFilterOption[]
  columnsOptions: ParsedGridColumnOption[]
  shownColumnsCount: number | undefined
  sortBy: GridDataViewSortBy | null
  modellerOn?: boolean
  hideModeller?: boolean
  hideLockCellButton?: boolean
  canOnlyApprove?: boolean
  isGridsOnlyUser: boolean
  cancelLabel?: string
  isMultipanelActive?: boolean
  asOfDate?: string | null
  onRowClick?: (rowRef: string) => void
  onSortChange: (sortBy: GridDataViewSortBy | null) => void
  onSecuritySelect?: (assetRef: string) => void
  onLiquiditySelect?: (assetRef: string) => void
  onAggregationsChange: (aggregations: string[]) => void
  onToggleHiddenColumn: (datapointRef: string) => void
  onUnhideAllColumns: () => void
  onMoveColumn: (fromDatapointRef: string, toDatapointRef: string) => void
  onMoveColumnToPanel?: (datapointRef: string) => void
  onRemoveColumnFromPanel?: (datapointRef: string) => void
  onSetColumnWidth?: (datapointRef: string, width: number) => void
  onRenameColumn?: (datapointRef: string, name: string) => void
  onAddFilters: (filters: GridDataViewFilters) => void
  onDeleteFilter: (payload: DeleteFilterPayload) => void
  onDeleteAllFilters: () => void
  onSetAsOfDate?: (asOfDate: string | null) => void
  onModellerSwitch?: (checked: boolean) => void
  onModellingOptionsClick?: () => void
  onModellerResetClick?: () => void
  onModellerSendToTradingClick?: () => void
  onMultipanelClick?: () => void
  onUpdateCell?: (cell: CellReference, value: CellValue, cellOptions: GridCellOption[]) => void
  onSelectOrder?: (orderRef: string) => void
  onPlaceOrder?: (orderRef: string) => void
  onConfirmTrade?: (dealRef: string) => void
  onCorrectTransaction?: (txnRef: string) => void
  onCloseOutTransaction?: (segmentRef: string) => void
  onClosePosition?: (yRef: string) => void
  onTransactionSelect?: (selectedTransaction: SelectedTransaction) => void
  onCancelTransaction?: () => void
}

export type CellReference = {
  rowRef: string
  datapointRef: string
  panelIndex: number | null
  panelType: PanelType | null
}

export type CellValue = {
  datapointType: DatapointType
  value: DatapointValueType
  isDirty: boolean
}

function PortfolioTable(props: PortfolioTableProps) {
  const {
    data,
    aggregations,
    filters,
    filterOptions,
    columnsOptions,
    shownColumnsCount,
    sortBy,
    modellerOn,
    hideModeller,
    hideLockCellButton,
    canOnlyApprove,
    isGridsOnlyUser,
    cancelLabel,
    isMultipanelActive,
    asOfDate,
    onRowClick,
    onSortChange,
    onAggregationsChange,
    onToggleHiddenColumn,
    onUnhideAllColumns,
    onMoveColumn,
    onMoveColumnToPanel,
    onRemoveColumnFromPanel,
    onSetColumnWidth,
    onRenameColumn,
    onAddFilters,
    onDeleteFilter,
    onDeleteAllFilters,
    onSetAsOfDate,
    onModellerSwitch,
    onModellingOptionsClick,
    onModellerResetClick,
    onModellerSendToTradingClick,
    onMultipanelClick,
    onUpdateCell,
    onSecuritySelect,
    onLiquiditySelect,
    onSelectOrder,
    onPlaceOrder,
    onConfirmTrade,
    onCorrectTransaction,
    onCloseOutTransaction,
    onClosePosition,
    onTransactionSelect,
    onCancelTransaction,
  } = props

  const { t } = useTranslation('dataTable')

  const [gridRef, setGridRef] = useState<RefObject<AgGridReact> | null>(null)

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
    cellReference?: CellReference
    canBeLocked: boolean
    assetRef: string | null
    txnRef: string | undefined
    dealRef: string | undefined
    segmentRef: string | undefined
    txnStatus: TxnStatus | undefined
    canConfirmTrade: boolean
    canCorrectTransaction: boolean
    canCancelTransaction: boolean
    canCloseOut: boolean
  } | null>(null)

  const [hiddenRowGroups, setHiddenRowGroups] = useState<{ [rowGroup: string]: boolean }>({})
  const [asOfDateMenuAnchorEl, setAsOfDateMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [modellerMenuAnchorEl, setModellerMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [hiddenColumnsMenuAnchorEl, setHiddenColumnsMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [lockedCells, setLockedCells] = useState<CellReference[]>([])

  const selectedGroups = aggregations
    .map((datapointRef) => {
      return data?.columnsOptions.find((option) => option.datapointRef === datapointRef)!
    })
    .filter(Boolean)

  const selectedFilters = parseFilters(filters || {}, filterOptions)

  function handleAddGroup(datapointRef: string) {
    const alreadyIncluded = aggregations.find((aggregation) => {
      return aggregation === datapointRef
    })
    if (!alreadyIncluded) {
      onAggregationsChange(aggregations.concat(datapointRef))
    }
  }

  function handleAsOfDateMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAsOfDateMenuAnchorEl(event.currentTarget)
  }

  function handleAsOfDateMenuClose() {
    setAsOfDateMenuAnchorEl(null)
  }

  function handleModellerMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setModellerMenuAnchorEl(event.currentTarget)
  }

  function handleModellerMenuClose() {
    setModellerMenuAnchorEl(null)
  }

  function handleModellerSwitch(checked: boolean) {
    onModellerSwitch?.(checked)
    handleModellerMenuClose()
  }

  function handleModellerSendToTradingClick() {
    onModellerSendToTradingClick?.()
    handleModellerMenuClose()
  }

  function handleMultipanelMenuOpen(_event: React.MouseEvent<HTMLButtonElement>) {
    onMultipanelClick?.()
  }

  function handleHiddenColumnsMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setHiddenColumnsMenuAnchorEl(event.currentTarget)
  }

  function handleHiddenColumnsMenuClose() {
    setHiddenColumnsMenuAnchorEl(null)
  }

  function handleFilterMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setFilterMenuAnchorEl(event.currentTarget)
  }

  function handleFilterMenuClose() {
    setFilterMenuAnchorEl(null)
  }

  function handleRemoveGroup(datapointRef: string) {
    const updatedAggregations = aggregations?.filter((aggregation) => aggregation !== datapointRef) || []
    onAggregationsChange(updatedAggregations)
  }

  function toggleRowGroupVisibility(groupAggregatePath: string) {
    setHiddenRowGroups((state) => ({
      ...state,
      [groupAggregatePath]: !state[groupAggregatePath],
    }))
  }

  function toggleGroupLevelVisibility(groupLevel: number) {
    if (data) {
      const pathsToHide = data.aggregatePaths.filter((aggregatePath) => aggregatePath.groupLevel === groupLevel)
      const pathsHidden = Object.entries(hiddenRowGroups).filter(([aggregatePath, hidden]) => {
        const level = getAggregatePathGroupLevel(aggregatePath)
        return level === groupLevel && hidden
      })

      // If all paths on same level are hidden, show them all
      const shouldHide = pathsToHide.length !== pathsHidden.length

      setHiddenRowGroups(
        data.aggregatePaths.reduce(
          (acc, aggregatePath) => {
            if (aggregatePath.groupLevel === groupLevel) {
              acc[aggregatePath.aggregatePath] = shouldHide
            } else {
              // Show all other paths
              acc[aggregatePath.aggregatePath] = false
            }
            return acc
          },
          {} as { [key: string]: boolean }
        )
      )
    }
  }

  function handleContextMenuOpen(event: MouseEvent, row: ParsedGridDataRow, cell?: ParsedGridDataCell) {
    event.preventDefault()

    onRowClick?.(row.rowRef)

    const cellReference = cell
      ? {
          rowRef: cell.rowRef,
          datapointRef: cell.datapointRef,
          panelIndex: cell.panelIndex,
          panelType: cell.panelType,
        }
      : undefined

    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      cellReference,
      canBeLocked: !!cell?.canLock,
      assetRef: row.assetRef,
      txnRef: row.txnRef,
      dealRef: row.dealRef,
      segmentRef: 'segment_ref' in row.meta ? row.meta.segment_ref : undefined,
      txnStatus: 'txn_status' in row.meta ? row.meta.txn_status : undefined,
      canConfirmTrade: row.canConfirmTrade,
      canCorrectTransaction: row.canCorrectTransaction,
      canCancelTransaction: row.canCancelTransaction,
      canCloseOut: row.canCloseOut,
    })
    onTransactionSelect?.({ txnRef: row.txnRef, txnStatus: 'txn_status' in row.meta ? row.meta.txn_status : undefined })
  }

  const handleContextMenuClose = () => {
    setContextMenu(null)
  }

  function handleCellClick(rowRef: string) {
    onRowClick?.(rowRef)

    if (onSelectOrder && rowRef.startsWith('OR')) {
      onSelectOrder(rowRef)
    }
  }

  function handleCellUpdate(cellReference: CellReference, cellValue: CellValue) {
    if (!onUpdateCell || !cellValue.isDirty) {
      return
    }

    const cellOptions: GridCellOption[] = lockedCells.map((lockedCell) => {
      return {
        y_ref: lockedCell.rowRef!,
        datapoint_ref: lockedCell.datapointRef!,
        panel_idx: lockedCell.panelIndex!,
        option_type: 'locked',
      }
    })

    onUpdateCell(cellReference, cellValue, cellOptions)
  }

  function handleLockCell() {
    const cellReference = contextMenu?.cellReference
    if (cellReference) {
      setLockedCells([...lockedCells, cellReference])
      handleContextMenuClose()
    }
  }

  function handleUnlockCell() {
    const cellReference = contextMenu?.cellReference
    const filteredCells = lockedCells.filter((cell) => {
      const isSameCell =
        cell.rowRef === cellReference?.rowRef &&
        cell.datapointRef === cellReference?.datapointRef &&
        cell.panelIndex === cellReference?.panelIndex

      return !isSameCell
    })

    setLockedCells(filteredCells)
    handleContextMenuClose()
  }

  function isCellLocked(cellReference?: CellReference) {
    if (!cellReference) {
      return false
    }

    return !!lockedCells.find((lockedCell) => {
      return areSameCellReferences(lockedCell, cellReference)
    })
  }

  function handlePlaceOrder() {
    const rowRef = contextMenu?.cellReference?.rowRef

    if (onPlaceOrder && rowRef && rowRef.startsWith('OR')) {
      onPlaceOrder(rowRef)
      handleContextMenuClose()
    }
  }

  function handleCorrectTransaction() {
    const txnRef = contextMenu?.txnRef

    if (onCorrectTransaction && txnRef) {
      onCorrectTransaction(txnRef)
      handleContextMenuClose()
    }
  }

  function handleCloseOutTransaction() {
    const segmentRef = contextMenu?.segmentRef

    if (onCloseOutTransaction && segmentRef) {
      onCloseOutTransaction(segmentRef)
      handleContextMenuClose()
    }
  }

  function handleConfirmTrade() {
    const dealRef = contextMenu?.dealRef

    if (onConfirmTrade && dealRef) {
      onConfirmTrade(dealRef)
      handleContextMenuClose()
    }
  }

  function handleClosePosition() {
    const yRef = contextMenu?.cellReference?.rowRef

    if (onClosePosition && yRef) {
      onClosePosition(yRef)
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: '0 0 64px',
          alignItems: 'center',
        }}
      >
        <GroupBySection
          groups={selectedGroups}
          onToggleGroupLevelVisibility={toggleGroupLevelVisibility}
          onRemoveGroup={handleRemoveGroup}
        />

        <Stack direction="row" gap={1} sx={{ ml: 'auto' }}>
          <FilterButton
            label={asOfDate ? asOfDate : t('current')}
            isActive={!!asOfDate}
            Icon={CalendarToday}
            showArrow={true}
            onClick={handleAsOfDateMenuOpen}
          />
          {!isGridsOnlyUser && !hideModeller && (
            <FilterButton
              label={t('modeller')}
              isActive={modellerOn}
              Icon={BuildCircle}
              onClick={handleModellerMenuOpen}
            />
          )}
          {!!onMultipanelClick && (
            <FilterButton
              label={t('multipanels')}
              isActive={isMultipanelActive}
              Icon={TableChart}
              onClick={handleMultipanelMenuOpen}
            />
          )}
          <FilterButton
            label={t('hidden_columns')}
            count={shownColumnsCount}
            Icon={ViewColumn}
            onClick={handleHiddenColumnsMenuOpen}
          />
          <FilterButton
            label={t('filter')}
            count={selectedFilters.length}
            Icon={FilterAlt}
            onClick={handleFilterMenuOpen}
          />
        </Stack>
      </div>

      <DataTable
        data={data}
        sortBy={sortBy}
        aggregations={aggregations}
        hiddenRowGroups={hiddenRowGroups}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        isGridsOnlyUser={isGridsOnlyUser}
        isCellLocked={isCellLocked}
        onCellClick={handleCellClick}
        onCellUpdate={handleCellUpdate}
        onContextMenuOpen={handleContextMenuOpen}
        onMoveColumn={onMoveColumn}
        onSortChange={onSortChange}
        onToggleRowGroupVisibility={toggleRowGroupVisibility}
        onHideColumn={onToggleHiddenColumn}
        onMoveToPanel={onMoveColumnToPanel}
        onRemoveFromPanel={onRemoveColumnFromPanel}
        onSetColumnWidth={onSetColumnWidth}
        onRenameColumn={onRenameColumn}
        onGroupBy={handleAddGroup}
        onAddFilters={onAddFilters}
        onDeleteFilter={onDeleteFilter}
        onForwardGridRef={setGridRef}
      />

      <ContextMenu
        assetRef={contextMenu?.assetRef}
        isLocked={isCellLocked(contextMenu?.cellReference)}
        hideLockCellButton={hideLockCellButton}
        canBeLocked={contextMenu?.canBeLocked}
        canConfirmTrade={contextMenu?.canConfirmTrade}
        canCorrectTransaction={!!contextMenu?.canCorrectTransaction && !!contextMenu?.txnRef}
        canCancelTransaction={contextMenu?.canCancelTransaction}
        canCloseOut={!!contextMenu?.canCloseOut}
        canOnlyApprove={canOnlyApprove}
        isGridsOnlyUser={isGridsOnlyUser}
        contextMenuCoordinates={contextMenu}
        cancelLabel={cancelLabel}
        onContextMenuClose={handleContextMenuClose}
        onSecuritySelect={onSecuritySelect}
        onLockCell={handleLockCell}
        onUnlockCell={handleUnlockCell}
        onLiquiditySelect={onLiquiditySelect}
        onPlaceOrder={onPlaceOrder && handlePlaceOrder}
        onConfirmTrade={onConfirmTrade && handleConfirmTrade}
        onCorrectTransaction={onCorrectTransaction && handleCorrectTransaction}
        onCloseOutTransaction={onCloseOutTransaction && handleCloseOutTransaction}
        onClosePosition={onClosePosition && handleClosePosition}
        onCancelTransaction={onCancelTransaction}
        onCopy={() => gridRef?.current?.api.copySelectedRangeToClipboard()}
      />
      {onSetAsOfDate && (
        <AsOfDateMenu
          anchorEl={asOfDateMenuAnchorEl}
          currentAsOfDate={asOfDate}
          onSetAsOfDate={onSetAsOfDate}
          onClose={handleAsOfDateMenuClose}
        />
      )}
      {!hideModeller && (
        <ModellerMenu
          anchorEl={modellerMenuAnchorEl}
          modellerOn={modellerOn || false}
          onModellerSwitch={handleModellerSwitch}
          onModellingOptionsClick={onModellingOptionsClick}
          onModellerResetClick={onModellerResetClick}
          onModellerSendToTradingClick={handleModellerSendToTradingClick}
          onClose={handleModellerMenuClose}
        />
      )}
      <ColumnsMenu
        anchorEl={hiddenColumnsMenuAnchorEl}
        columns={columnsOptions}
        onClose={handleHiddenColumnsMenuClose}
        onHideColumn={onToggleHiddenColumn}
        onUnhideAll={onUnhideAllColumns}
      />
      <FilterMenu
        anchorEl={filterMenuAnchorEl}
        filterOptions={filterOptions}
        selectedFilters={selectedFilters}
        onChange={onAddFilters}
        onDelete={onDeleteFilter}
        onDeleteAll={onDeleteAllFilters}
        onClose={handleFilterMenuClose}
      />
    </>
  )
}

export default PortfolioTable

function areSameCellReferences(cellA: CellReference, cellB: CellReference) {
  const isSameRow = cellA.rowRef === cellB.rowRef
  const isSameDatapoint = cellA.datapointRef === cellB.datapointRef
  const isSamePanel = cellA.panelIndex === cellB.panelIndex && cellA.panelType === cellB.panelType

  return isSameRow && isSameDatapoint && isSamePanel
}
